import React from "react";

export const Footer = () => {
  return (
    <div>
      {/* <div>
<div>
  <img src={require("../assets/shape-bg.png")} alt= "no picture" />
</div> */}
      <footer className="bg-dark text-light p-5 text-center mt-3 ">
        Copyright &copy; All right Reserved. Shital Rayamajhi.
      </footer>
    </div>
  );
};
